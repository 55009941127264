import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import "typeface-roboto"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
// import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
// import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Button from "@material-ui/core/Button"

import Typography from "@material-ui/core/Typography"

import Card from "../Card/Card.js"
import CardBody from "../Card/CardBody.js"
import CardHeader from "../Card/CardHeader.js"
import CardFooter from "../Card/CardFooter.js"

import GridContainer from "../Grid/GridContainer.js"
import GridItem from "../Grid/GridItem.js"

import styles from "assets/jss/material-kit-react/views/loginPage.js"

import img_yk from "assets/img/yk.jpg"
import img_blachford from "assets/img/blachford.jpg"
import img_iceland from "assets/img/iceland.jpg"

const useStyles = makeStyles(styles)

const IndexPage = () => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                image={img_yk}
                title="黃刀鎮五天四夜遊"
              />
              <CardBody className={classes.cardHeader}>
                <Typography gutterBottom variant="h6" component="h2">
                  5天4夜團
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  黃刀鎮
                </Typography>
              </CardBody>
              <CardFooter>
                <Link to="/YK5days">
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                  >
                    報名
                  </Button>
                </Link>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                image={img_blachford}
                title="黃刀鎮Blachford"
              />
              <CardBody className={classes.cardHeader}>
                <Typography gutterBottom variant="h6" component="h2">
                  Blachford小木屋
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  黃刀鎮
                </Typography>
              </CardBody>
              <CardFooter>
                <Link to="/Blachford">
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                  >
                    報名
                  </Button>
                </Link>
              </CardFooter>
            </Card>
          </GridItem>
          {/* <GridItem xs={12} sm={4}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                image={img_iceland}
                title="冰島團"
              />
              <CardBody className={classes.cardHeader}>
                <Typography gutterBottom variant="h6" component="h2">
                  冰島團
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  冰島
                </Typography>
              </CardBody>
              <CardFooter>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                  disabled
                >
                  2020
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                  disabled
                >
                  2021
                </Button>
              </CardFooter>
            </Card>
          </GridItem> */}
        </GridContainer>
      </div>
    </>
  )
}

export default IndexPage
