// import React from "react"
import React, { useContext } from "react"
// import { useStaticQuery, graphql } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"
// import {
//   GlobalDispatchContext,
//   GlobalStateContext,
// } from "context/GlobalContextProvider"
import GroupSelections from "components/Groups/GroupSelections"
const IndexPage = () => {
  //   const state = useContext(GlobalStateContext)

  return (
    <Layout>
      <SEO title="首頁" />
      <GroupSelections />
    </Layout>
  )
}

export default IndexPage
